module.exports = {
  defaultTitle: 'Simcha Greenbaum',
  logo:
    'https://www.namecheap.com/logo-maker/app/share?c=iconAbove&l=2e7a0b90-f68e-11e9-adb7-b512ff026890',
  author: 'Simcha Greenbaum',
  url: 'www.simchagreenbaum.dev',
  legalName: 'Simcha Greenbaum',
  defaultDescription:
    'I’m Software engineer with a passion for learning and building, possessing experience in Ruby on Rails, JavaScript, React and Redux.',
  socialLinks: {
    twitter: 'http://www.twitter.com/simgreenbaum',
    github: 'https://github.com/gbs4ever',
    linkedin: 'https://www.linkedin.com/in/simcha-greenbaum/',
  },
  googleAnalyticsID: 'UA-154716108-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@simgreenbaum',
  },
  address: {
    city: 'NY',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'simchagreenbaum@gmail.com',
    phone: 'phone number',
  },
  foundingDate: '2018',
  recaptcha_key: '6LdpO74UAAAAAFpz2FZGnOzmIzq7FrcBtmjf7IYe',
}
